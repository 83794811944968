.player {
    min-height: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.timeControl {
    width: 50%;
    display: flex;
    align-items: center;
    input {
        width: 100%;
        -webkit-appearance: none;
        background: transparent;
        cursor: pointer;
    }
    p {
        padding: 1rem;
        min-width: 64px;
        text-align: center;
    }
}

.playControl {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    svg {
        cursor: pointer;
    }
}

// VolumeSlider
.volumeSlider {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 16rem;
    height: 1rem;
    margin-top: 0.5rem;
}

// Design Inputslider
// See also input{} in .timeControl
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    height: 16px;
    width: 16px;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    height: 16px;
    width: 16px;
}

.track {
    background: lightblue;
    width: 100%;
    height: 1rem;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
}

.animateTrack {
    background: rgb(204, 204, 204);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0%);
    pointer-events: none;
}

@media screen and (max-width: 768px) {
    .timeControl {
        width: 90%;
    }
    .playControl {
        width: 65%;
    }
}

// NEW SLIDER HERE
.heat-slider {
    position: relative;
    font-size: 1em;
    line-height: 0;
}

@media (pointer: coarse) {
    .heat-slider {
        display: none;
    }
}

.heat-slider:before,
.heat-slider:after {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.heat-slider:before {
    background: #d8d8d8;
    border-radius: 1em;
}
.heat-slider:after {
    top: calc(50% - 0.2em);
    bottom: calc(50% - 0.2em);
    background: repeating-linear-gradient(
        to right,
        transparent 0%,
        transparent calc(20% - 0.4em),
        rgba(0, 0, 0, 0.35) calc(20% - 0.4em),
        rgba(0, 0, 0, 0.35) 20%
    );
}
.heat-slider .heat-bar {
    top: 0;
    right: 1rem;
    bottom: 0;
    left: 0rem;
    width: 16rem;
    z-index: 1;
    border-radius: 1rem;
    background: linear-gradient(to right, #74c35a, #ff7f36);
}
.heat-slider .heat-bar div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: #d8d8d8;
    background: linear-gradient(to right, #8ddcdf, #7593d8);

    border-radius: 1em 1em 1em 1em;
    margin-right: 0em;
}

.heat-slider input {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    //margin: 0 0 0 calc(20% - 0.5em);
    //width: calc(80% - 0.5em);
    width: 100%;
    height: 0.5rem;
    background: none;
    z-index: 0;
    box-sizing: border-box;
    font-size: 1em;
}
.heat-slider input::-webkit-slider-runnable-track {
    cursor: pointer;
}
.heat-slider input::-moz-range-track {
    cursor: pointer;
    background: transparent;
}
.heat-slider input::-ms-track {
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
    border-width: 0;
}
.heat-slider input::-ms-fill-lower {
    background: transparent;
}
.heat-slider input::-ms-fill-upper {
    background: transparent;
}
.heat-slider input::-webkit-slider-thumb {
    font-size: 1em;
    box-shadow: 0 0 0 0.15em rgba(0, 0, 0, 0.2) inset, 0 0 0 0.15em #fff;
    height: 1.5em;
    width: 1.5em;
    border-radius: 1em;
    background: #d8d8d8;
    cursor: pointer;
    -webkit-appearance: none;
}
.heat-slider input::-moz-range-thumb {
    font-size: 1em;
    box-shadow: 0 0 0 0.15em rgba(0, 0, 0, 0.2) inset, 0 0 0 0.15em #fff;
    height: 1.5em;
    width: 1.5em;
    border-radius: 1em;
    background: #d8d8d8;
    cursor: pointer;
}
.heat-slider input::-ms-thumb {
    font-size: 1em;
    box-shadow: 0 0 0 0.15em rgba(0, 0, 0, 0.2) inset, 0 0 0 0.15em #fff;
    height: 1.5em;
    width: 1.5em;
    border-radius: 1em;
    background: #d8d8d8;
    cursor: pointer;
}
.heat-slider input:focus {
    outline: none;
}
.heat-slider input:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.15em rgba(0, 0, 0, 0.2) inset, 0 0 0 0.15em #fff,
        0 0 1em;
}
.heat-slider input:focus::-moz-range-thumb {
    box-shadow: 0 0 0 0.15em rgba(0, 0, 0, 0.2) inset, 0 0 0 0.15em #fff,
        0 0 1em;
}
.heat-slider input:focus::-ms-thumb {
    box-shadow: 0 0 0 0.15em rgba(0, 0, 0, 0.2) inset, 0 0 0 0.15em #fff,
        0 0 1em;
}
.heat-slider input::-moz-focus-outer {
    border: 0;
}
